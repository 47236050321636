import http from '@/http.js';

export class TenantService {
  static getTenants() {
    return http.get('/api/tenants');
  }

  static getTenantDetails(id) {
    return http.get(`/api/tenants/${id}`);
  }

  static createTenant(data) {
    return http.post('/api/tenants', JSON.stringify(data));
  }

  static updateTenant(id, data) {
    return http.put(`/api/tenants/${id}`, JSON.stringify(data));
  }

  static deleteTenant(id) {
    return http.delete(`/api/tenants/${id}`);
  }
}
