import { ResearchService } from '@/services/research.service.js';

const state = {
  researches: null,
  selectedResearch: null,
  error: null,
  success: null
};

const getters = {
  researches: state => state.researches,
  selectedResearch: state => state.selectedResearch,
  researchError: state => state.error,
  researchSuccess: state => state.success,
  researchSelectedPatientId: (state, getters, rootState) => {
    return rootState.patient.selectedPatient.id;
  },
  researchSelectedStudentId: (state, getters, rootState) => {
    if (rootState.user.selectedStudent === null) return undefined;
    return rootState.user.selectedStudent.id;
  }
};

const actions = {
  async fetchResearches({ commit, getters }, data) {
    try {
      const studentId = getters.researchSelectedStudentId === undefined ? "0" : getters.researchSelectedStudentId;
      const response = await ResearchService.getPatientResearches(data, {'selected_user': studentId});
      commit('SET_RESEARCHES', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching researches');
    }
  },
  async createResearch({ commit, getters }, data) {
    try {
      const patientId = getters.researchSelectedPatientId;
      const response = await ResearchService.createPatientResearch(patientId, data);
      commit('SET_SUCCESS', 'Research report created successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating research');
    }
  },
  async updateResearch({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.researchSelectedPatientId;
      const response = await ResearchService.updatePatientResearch(patientId, id, data);
      commit('SET_SUCCESS', 'Research report updated successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating research');
    }
  },
  async deleteResearch({ commit, getters }, id) {
    try {
      const patientId = getters.researchSelectedPatientId;
      await ResearchService.deletePatientResearch(patientId, id);
      commit('SET_SUCCESS', 'Research report deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting research');
    }
  },
  removeError({ commit }) {
    commit('SET_ERROR', null);
  },
  removeSuccess({ commit }) {
    commit('SET_SUCCESS', null);
  }
};

const mutations = {
  SET_RESEARCHES: (state, researches) => {
    state.researches = researches;
  },
  SET_SELECTED_RESEARCH: (state, research) => {
    state.selectedResearch = research;
  },
  clearResearch(state) {
    state.researches = null;
    state.selectedResearch = null;
    state.error = null;
    state.success = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
