import http from '@/http.js';

export class UserService {
  static getUsers() {
    return http.get('/api/users');
  }

  static getUserDetails(id) {
    return http.get(`/api/users/${id}`);
  }

  static createUser(data) {
    return http.post('/api/users', JSON.stringify(data));
  }

  static updateUser(id, data) {
    return http.put(`/api/users/${id}`, JSON.stringify(data));
  }

  static deleteUser(id) {
    return http.delete(`/api/users/${id}`);
  }

  static getStudents() {
    return http.get('/api/users/students');
  }

  static deleteStudents() {
    return http.delete('/api/users/students');
  }

  static deleteStudentsData() {
    return http.delete('/api/users/students-data');
  }
}
