import { TenantService } from '@/services/tenant.service.js';

const state = {
  tenants: null,
  selectedTenant: null,
  error: null,
  success: null
};

const getters = {
  tenants: state => state.tenants,
  selectedTenant: state => state.selectedTenant,
  tenantError: state => state.error,
  tenantSuccess: state => state.success
};

const actions = {
  async fetchTenants({ commit }) {
    try {
      const response = await TenantService.getTenants();
      commit('SET_TENANTS', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching tenants');
    }
  },
  async createTenant({ commit }, data) {
    try {
      await TenantService.createTenant(data);
      commit('SET_SUCCESS', 'Tenant created successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating tenant');
    }
  },
  async updateTenant({ commit }, { id, data }) {
    try {
      await TenantService.updateTenant(id, data);
      commit('SET_SUCCESS', 'Tenant updated successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating tenant');
    }
  },
  async deleteTenant({ commit }, id) {
    try {
      await TenantService.deleteTenant(id);
      commit('SET_SUCCESS', 'Tenant deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting tenant');
    }
  },
  removeError({ commit }) {
    commit('SET_ERROR', null);
  },
  removeSuccess({ commit }) {
    commit('SET_SUCCESS', null);
  }
};

const mutations = {
  SET_TENANTS: (state, tenants) => {
    state.tenants = tenants;
  },
  SET_SELECTED_TENANT: (state, tenant) => {
    state.selectedTenant = tenant;
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
  SET_SUCCESS: (state, success) => {
    state.success = success;
  },
  clearTenant(state) {
    state.tenants = null;
    state.selectedTenant = null;
    state.error = null;
    state.success = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
