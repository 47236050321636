import axios from 'axios'
import store from '@/store'
import { encryptStorage } from '@/constants.js'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    accept: 'application/json'
  }
})

apiClient.interceptors.request.use((config) => {
  if (encryptStorage.getItem('epd-access-token')) {
    config.headers['Authorization'] = `Bearer ${encryptStorage.getItem('epd-access-token')}`
  }

  return config
})

apiClient.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 401 && !location.href.endsWith('login') && !location.href.endsWith('forgot-password')) {
    store.dispatch('logout')
  }
  return error
})

export default apiClient
