import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/',
    alias: '/',
    meta: { login: true },
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard'),
        meta: { login: true }
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard'),
        meta: { login: true }
      },
      {
        path: '/logs',
        name: 'LogList',
        component: () => import('@/views/log/LogList'),
        meta: { login: true, groups: ['docent', 'admin', 'superadmin'] }
      },
      {
        path: '/tenants',
        name: 'TenantList',
        component: () => import('@/views/tenant/TenantList'),
        meta: { login: true, groups: ['superadmin'] }
      },
      {
        path: '/tenants/:id/edit',
        name: 'EditTenant',
        component: () => import('@/views/tenant/EditTenant'),
        meta: { login: true, groups: ['superadmin'] }
      },
      {
        path: '/tenants/new',
        name: 'NewTenant',
        component: () => import('@/views/tenant/NewTenant'),
        meta: { login: true, groups: ['superadmin'] }
      },
      {
        path: '/users',
        name: 'UsersList',
        component: () => import('@/views/user/UserList'),
        meta: { login: true, groups: ['admin', 'superadmin'] }
      },
      {
        path: '/users/:id/edit',
        name: 'EditUser',
        component: () => import('@/views/user/EditUser'),
        meta: { login: true, groups: ['admin', 'superadmin'] }
      },
      {
        path: '/users/new',
        name: 'NewUser',
        component: () => import('@/views/user/NewUser'),
        meta: { login: true, groups: ['admin', 'superadmin'] }
      },
      {
        path: '/patients',
        name: 'PatientList',
        component: () => import('@/views/patients/PatientList'),
        meta: { login: true, groups: ['docent', 'admin', 'superadmin'] }
      },
      {
        path: '/patients/:id/edit',
        name: 'EditPatient',
        component: () => import('@/views/patients/EditPatient'),
        meta: { login: true, groups: ['docent', 'admin', 'superadmin'] }
      },
      {
        path: '/patients/new',
        name: 'NewPatient',
        component: () => import('@/views/patients/NewPatient'),
        meta: { login: true, groups: ['docent', 'admin', 'superadmin'] }
      },
      {
        path: '/algemene-informatie',
        name: 'AlgemeneInformatie',
        component: () => import('@/views/patient/AlgemeneInformatie'),
        meta: { login: true }
      },
      {
        path: '/parameters',
        name: 'Parameters',
        component: () => import('@/views/patient/Parameters'),
        meta: { login: true }
      },
      {
        path: '/medicatie',
        name: 'Medicatie',
        component: () => import('@/views/patient/Medicatie'),
        meta: { login: true }
      },
      {
        path: '/onderzoeken',
        name: 'Onderzoeken',
        component: () => import('@/views/patient/Onderzoeken'),
        meta: { login: true }
      },
      {
        path: '/onderzoek/:id',
        name: 'Onderzoek',
        component: () => import('@/views/patient/Onderzoek'),
        meta: { login: true }
      },
      {
        path: '/adl',
        name: 'ADL',
        component: () => import('@/views/zorgmodule/ADL'),
        meta: { login: true }
      },
      {
        path: '/katheterzorg',
        name: 'Katheterzorg',
        component: () => import('@/views/zorgmodule/Katheterzorg'),
        meta: { login: true }
      },
      {
        path: '/wondzorg',
        name: 'Wondzorg',
        component: () => import('@/views/zorgmodule/Wondzorg'),
        meta: { login: true }
      },
      {
        path: '/symptoommanagement',
        name: 'Symptoommanagement',
        component: () => import('@/views/zorgmodule/Symptoommanagement'),
        meta: { login: true }
      },
      {
        path: '/verpleegkundig',
        name: 'Verpleegkundig',
        component: () => import('@/views/verslagen/Verpleegkundig'),
        meta: { login: true }
      },
      {
        path: '/medisch',
        name: 'Medisch',
        component: () => import('@/views/verslagen/Medisch'),
        meta: { login: true }
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login'),
    meta: { login: false },
  },
  {
    path: '/sso',
    name: 'Sso',
    component: () => import('@/views/auth/Sso'),
    meta: { login: false },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/auth/ForgotPassword'),
    meta: { login: false }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('@/views/auth/ResetPassword'),
    meta: { login: false }
  },
  {
    path: '/niet-geauthoriseerd',
    name: '403',
    component: () => import('@/views/NoAccess.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta?.login === true && !store.getters.user) {
    return next('/login')
  }

  if (to.meta?.login === false && store.getters.user) {
    return next('/dashboard')
  }
  
  if (to.meta?.groups && !to.meta.groups.includes(store.getters.user?.group)) {
    return next('/niet-geauthoriseerd')
  }

  next()
})

export default router
