import http from '@/http.js';

export class ADLService {
  static getPatientHygienes(patientId, data) {
    return http.post(`/api/patients/${patientId}/adl/hygiene/get`, JSON.stringify(data));
  }

  static getHygieneDetails(patientId, hygieneId) {
    return http.get(`/api/patients/${patientId}/adl/hygiene/${hygieneId}`);
  }

  static createPatientHygiene(patientId, data) {
    return http.post(`/api/patients/${patientId}/adl/hygiene`, JSON.stringify(data));
  }

  static updatePatientHygiene(patientId, hygieneId, data) {
    return http.put(`/api/patients/${patientId}/adl/hygiene/${hygieneId}`, JSON.stringify(data));
  }

  static deletePatientHygiene(patientId, hygieneId) {
    return http.delete(`/api/patients/${patientId}/adl/hygiene/${hygieneId}`);
  }

  static getPatientMobilityInstallationRoom(patientId, data) {
    return http.post(`/api/patients/${patientId}/adl/mobility_installation_room/get`, JSON.stringify(data));
  }

  static getMobilityInstallationRoomDetails(patientId, mobilityInstallationRoomId) {
    return http.get(`/api/patients/${patientId}/adl/mobility_installation_room/${mobilityInstallationRoomId}`);
  }

  static createPatientMobilityInstallationRoom(patientId, data) {
    return http.post(`/api/patients/${patientId}/adl/mobility_installation_room`, JSON.stringify(data));
  }

  static updatePatientMobilityInstallationRoom(patientId, mobilityInstallationRoomId, data) {
    return http.put(`/api/patients/${patientId}/adl/mobility_installation_room/${mobilityInstallationRoomId}`, JSON.stringify(data));
  }

  static deletePatientMobilityInstallationRoom(patientId, mobilityInstallationRoomId) {
    return http.delete(`/api/patients/${patientId}/adl/mobility_installation_room/${mobilityInstallationRoomId}`);
  }

  static getPatientMobilityDecubitus(patientId, data) {
    return http.post(`/api/patients/${patientId}/adl/mobility_decubitus/get`, JSON.stringify(data));
  }

  static getMobilityDecubitusDetails(patientId, mobilityDecubitusId) {
    return http.get(`/api/patients/${patientId}/adl/mobility_decubitus/${mobilityDecubitusId}`);
  }

  static createPatientMobilityDecubitus(patientId, data) {
    return http.post(`/api/patients/${patientId}/adl/mobility_decubitus`, JSON.stringify(data));
  }

  static updatePatientMobilityDecubitus(patientId, mobilityDecubitusId, data) {
    return http.put(`/api/patients/${patientId}/adl/mobility_decubitus/${mobilityDecubitusId}`, JSON.stringify(data));
  }

  static deletePatientMobilityDecubitus(patientId, mobilityDecubitusId) {
    return http.delete(`/api/patients/${patientId}/adl/mobility_decubitus/${mobilityDecubitusId}`);
  }

  static getPatientNutrition(patientId, data) {
    return http.post(`/api/patients/${patientId}/adl/nutrition/get`, JSON.stringify(data));
  }

  static getNutritionDetails(patientId, nutritionId) {
    return http.get(`/api/patients/${patientId}/adl/nutrition/${nutritionId}`);
  }

  static createPatientNutrition(patientId, data) {
    return http.post(`/api/patients/${patientId}/adl/nutrition`, JSON.stringify(data));
  }

  static updatePatientNutrition(patientId, nutritionId, data) {
    return http.put(`/api/patients/${patientId}/adl/nutrition/${nutritionId}`, JSON.stringify(data));
  }

  static deletePatientNutrition(patientId, nutritionId) {
    return http.delete(`/api/patients/${patientId}/adl/nutrition/${nutritionId}`);
  }

  static getPatientUrinaryExcretion(patientId, data) {
    return http.post(`/api/patients/${patientId}/adl/urinary_excretion/get`, JSON.stringify(data));
  }

  static getUrinaryExcretionDetails(patientId, urinaryExcretionId) {
    return http.get(`/api/patients/${patientId}/adl/urinary_excretion/${urinaryExcretionId}`);
  }

  static createPatientUrinaryExcretion(patientId, data) {
    return http.post(`/api/patients/${patientId}/adl/urinary_excretion`, JSON.stringify(data));
  }

  static updatePatientUrinaryExcretion(patientId, urinaryExcretionId, data) {
    return http.put(`/api/patients/${patientId}/adl/urinary_excretion/${urinaryExcretionId}`, JSON.stringify(data));
  }

  static deletePatientUrinaryExcretion(patientId, urinaryExcretionId) {
    return http.delete(`/api/patients/${patientId}/adl/urinary_excretion/${urinaryExcretionId}`);
  }

  static getPatientFecalExcretion(patientId, data) {
    return http.post(`/api/patients/${patientId}/adl/fecal_excretion/get`, JSON.stringify(data));
  }

  static getFecalExcretionDetails(patientId, fecalExcretionId) {
    return http.get(`/api/patients/${patientId}/adl/fecal_excretion/${fecalExcretionId}`);
  }

  static createPatientFecalExcretion(patientId, data) {
    return http.post(`/api/patients/${patientId}/adl/fecal_excretion`, JSON.stringify(data));
  }

  static updatePatientFecalExcretion(patientId, fecalExcretionId, data) {
    return http.put(`/api/patients/${patientId}/adl/fecal_excretion/${fecalExcretionId}`, JSON.stringify(data));
  }

  static deletePatientFecalExcretion(patientId, fecalExcretionId) {
    return http.delete(`/api/patients/${patientId}/adl/fecal_excretion/${fecalExcretionId}`);
  }
}
