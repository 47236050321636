import http from '@/http.js';

export class CatheterCareService {
  static getPatientCatheterCares(patientId, data) {
    return http.post(`/api/patients/${patientId}/catheter_care/get`, JSON.stringify(data));
  }

  static getCatheterCareDetails(patientId, catheterCareId) {
    return http.get(`/api/patients/${patientId}/catheter_care/${catheterCareId}`);
  }

  static createPatientCatheterCare(patientId, data) {
    return http.post(`/api/patients/${patientId}/catheter_care`, JSON.stringify(data));
  }

  static updatePatientCatheterCare(patientId, catheterCareId, data) {
    return http.put(`/api/patients/${patientId}/catheter_care/${catheterCareId}`, JSON.stringify(data));
  }

  static deletePatientCatheterCare(patientId, catheterCareId) {
    return http.delete(`/api/patients/${patientId}/catheter_care/${catheterCareId}`);
  }
}
