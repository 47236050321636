import http from '@/http.js';

export class MedicationService {
  static getPatientMedications(patientId, data) {
    return http.post(`/api/patients/${patientId}/medication/get`, JSON.stringify(data));
  }

  static getMedicationDetails(patientId, medicationId) {
    return http.get(`/api/patients/${patientId}/medication/${medicationId}`);
  }

  static createPatientMedication(patientId, data) {
    return http.post(`/api/patients/${patientId}/medication`, JSON.stringify(data));
  }

  static updatePatientMedication(patientId, medicationId, data) {
    return http.put(`/api/patients/${patientId}/medication/${medicationId}`, JSON.stringify(data));
  }

  static deletePatientMedication(patientId, medicationId) {
    return http.delete(`/api/patients/${patientId}/medication/${medicationId}`);
  }

  static createPatientMedicationDate(patientId, data) {
    return http.post(`/api/patients/${patientId}/medication/date`, JSON.stringify(data));
  }

  static updatePatientMedicationDate(patientId, medicationId, data) {
    return http.put(`/api/patients/${patientId}/medication/date/${medicationId}`, JSON.stringify(data));
  }

  static deletePatientMedicationDate(patientId, medicationDateId) {
    return http.delete(`/api/patients/${patientId}/medication/date/${medicationDateId}`);
  }
}
