<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
export default {
  mounted() {
    document.title = 'Odilon';
    const script = document.createElement('script');
    script.src = 'https://plausible.pxl.be/js/script.js';
    script.setAttribute('defer', true);
    script.setAttribute('data-domain', 'odilon.eu');
    document.head.appendChild(script);
  }
}
</script>
