import { PatientService } from '@/services/patient.service.js';
import { AllergyService } from '@/services/allergy.service.js';
import { encryptStorage } from '@/constants.js'
import { ContactPersonService } from '@/services/contact_person.service';
import store from '../index.js';

const state = {
  patients: null,
  selectedPatient: null,
  filteredPatients: null,
  error: null,
  success: null,
};

const getters = {
  patients: (state) => state.patients,
  selectedPatient: (state) => state.selectedPatient,
  filteredPatients: (state) => state.filteredPatients,
  patientError: (state) => state.error,
  patientSuccess: (state) => state.success,
  patientSelectedStudentId: (state, getters, rootState) => {
    if (rootState.user.selectedStudent === null) return undefined;
    return rootState.user.selectedStudent.id;
  }
};

const actions = {
  async fetchPatients({ commit, getters }) {
    try {
      const studentId = getters.patientSelectedStudentId === undefined ? "0" : getters.patientSelectedStudentId;
      const response = await PatientService.getPatientsAllInfo({'selected_user': studentId});
      commit('SET_PATIENTS', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching patients');
    }
  },
  async createPatient({ commit }, data) {
    try {
      await PatientService.createPatient(data);
      commit('SET_SUCCESS', 'Patient created successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating patient');
    }
  },
  async updatePatient({ commit }, { id, data }) {
    try {
      await PatientService.updatePatient(id, data);
      commit('SET_SUCCESS', 'Patient updated successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating patient');
    }
  },
  async deletePatient({ commit }, id) {
    try {
      await PatientService.deletePatient(id);
      commit('SET_SUCCESS', 'Patient deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting patient');
    }
  },
  searchPatients({ commit, state }, searchQuery) {
    try {
      const filteredPatients = state.patients.filter((patient) =>
        `${patient.first_name} ${patient.last_name}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );

      commit('SET_FILTERED_PATIENTS', filteredPatients);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while searching for patients');
    }
  },
  removeError({ commit }) {
    commit('SET_ERROR', null);
  },
  removeSuccess({ commit }) {
    commit('SET_SUCCESS', null);
  },
  setSelectedPatient({ commit }, patient) {
    commit('SET_SELECTED_PATIENT', patient);
  },
  setSelectedPatientById({ commit, state }, id) {
    const patient = state.patients.find((patient) => patient.id === id.toString());
    if (patient) {
      commit('SET_SELECTED_PATIENT', patient);
    }
  },
  async createAllergy({ commit, state }, allergy) {
    try {
      const patientId = state.selectedPatient.id;
      const response = await AllergyService.createPatientAllergy(patientId, { name: allergy });
      commit('SET_SUCCESS', 'Allergy successfully added');
      return response.data
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while adding allergy');
    }
  },
  async removeAllergy({ commit, state }, allergyId) {
    try {
      const patientId = state.selectedPatient.id;
      await AllergyService.deletePatientAllergy(patientId, allergyId);
      commit('SET_SUCCESS', 'Allergy successfully removed');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while removing allergy');
    }
  },
  async createContactPerson({ commit, state }, contactPerson) {
    try {
      const patientId = state.selectedPatient.id;
      const response = await ContactPersonService.createPatientContactPerson(patientId, contactPerson);
      commit('SET_SUCCESS', 'Contactperson successfully added');
      return response.data
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while adding contactperson');
    }
  },
  async removeContactPerson({ commit, state }, contactPersonId) {
    try {
      const patientId = state.selectedPatient.id;
      await ContactPersonService.deletePatientContactPerson(patientId, contactPersonId);
      commit('SET_SUCCESS', 'Contactperson successfully removed');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while removing contactperson');
    }
  },
  async editAdmissionInformation({ commit, state }, admissionInformation) {
    try {
      const patientId = state.selectedPatient.id;
      await PatientService.editAdmissionInformation(patientId, admissionInformation);
      commit('SET_SUCCESS', 'Admission information successfully edited');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while editing admission information');
    }
  }
};

const mutations = {
  SET_PATIENTS: (state, patients) => {
    state.patients = patients;
    state.filteredPatients = patients;
  },
  SET_SELECTED_PATIENT: (state, patient) => {
    state.selectedPatient = patient;
    encryptStorage.setItem('epd-p', patient.id)
    store.commit('clearADL');
    store.commit('clearCatheterCare');
    store.commit('clearMedication');
    store.commit('clearParameter');
    store.commit('clearReport');
    store.commit('clearResearch');
    store.commit('clearSymptomManagement');
    store.commit('clearWoundCare');
  },
  SET_FILTERED_PATIENTS: (state, filteredPatients) => {
    state.filteredPatients = filteredPatients;
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
  SET_SUCCESS: (state, success) => {
    state.success = success;
  },
  clearPatient(state) {
    state.patients = null;
    state.selectedPatient = null;
    state.filteredPatients = null;
    state.error = null;
    state.success = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
