import { WoundCareService } from '@/services/wound_care.service.js';

const state = {
  woundCares: null,
  selectedWoundCare: null,
  drains: null,
  selectedDrain: null,
  chestDrains: null,
  selectedChestDrain: null,
  error: null,
  success: null
};

const getters = {
  woundCares: state => state.woundCares,
  selectedWoundCare: state => state.selectedWoundCare,
  drains: state => state.drains,
  selectedDrain: state => state.selectedDrain,
  chestDrains: state => state.chestDrains,
  selectedChestDrain: state => state.selectedChestDrain,
  woundCareError: state => state.error,
  woundCareSuccess: state => state.success,
  woundCareSelectedPatientId: (state, getters, rootState) => {
    return rootState.patient.selectedPatient.id;
  },
  woundCareSelectedStudentId: (state, getters, rootState) => {
    if (rootState.user.selectedStudent === null) return undefined;
    return rootState.user.selectedStudent.id;
  }
};

const actions = {
  async fetchWoundCares({ commit, getters }, data) {
    try {
      const studentId = getters.woundCareSelectedStudentId === undefined ? "0" : getters.woundCareSelectedStudentId;
      const response = await WoundCareService.getPatientWoundCare(data, {'selected_user': studentId});
      commit('SET_WOUND_CARES', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching wound cares');
    }
  },
  async createWoundCare({ commit, getters }, data) {
    try {
      const patientId = getters.woundCareSelectedPatientId;
      const response = await WoundCareService.createPatientWoundCare(patientId, data);
      commit('SET_SUCCESS', 'Wound care created successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating wound care');
    }
  },
  async updateWoundCare({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.woundCareSelectedPatientId;
      const response = await WoundCareService.updatePatientWoundCare(patientId, id, data);
      commit('SET_SUCCESS', 'Wound care updated successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating wound care');
    }
  },
  async deleteWoundCare({ commit, getters }, id) {
    try {
      const patientId = getters.woundCareSelectedPatientId;
      await WoundCareService.deletePatientWoundCare(patientId, id);
      commit('SET_SUCCESS', 'Wound care deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting wound care');
    }
  },
  async fetchDrains({ commit, getters }, data) {
    try {
      const studentId = getters.woundCareSelectedStudentId === undefined ? "0" : getters.woundCareSelectedStudentId;
      const response = await WoundCareService.getPatientDrain(data, {'selected_user': studentId});
      commit('SET_DRAINS', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching drains');
    }
  },
  async createDrain({ commit, getters }, data) {
    try {
      const patientId = getters.woundCareSelectedPatientId;
      const response = await WoundCareService.createPatientDrain(patientId, data);
      commit('SET_SUCCESS', 'Drain created successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating drain');
    }
  },
  async updateDrain({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.woundCareSelectedPatientId;
      const response = await WoundCareService.updatePatientDrain(patientId, id, data);
      commit('SET_SUCCESS', 'Drain updated successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating drain');
    }
  },
  async deleteDrain({ commit, getters }, id) {
    try {
      const patientId = getters.woundCareSelectedPatientId;
      await WoundCareService.deletePatientDrain(patientId, id);
      commit('SET_SUCCESS', 'Drain deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting drain');
    }
  },
  async fetchChestDrains({ commit, getters }, data) {
    try {
      const studentId = getters.woundCareSelectedStudentId === undefined ? "0" : getters.woundCareSelectedStudentId;
      const response = await WoundCareService.getPatientChestDrain(data, {'selected_user': studentId});
      commit('SET_CHEST_DRAINS', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching chest drains');
    }
  },
  async createChestDrain({ commit, getters }, data) {
    try {
      const patientId = getters.woundCareSelectedPatientId;
      const response = await WoundCareService.createPatientChestDrain(patientId, data);
      commit('SET_SUCCESS', 'Chest drain created successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating chest drain');
    }
  },
  async updateChestDrain({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.woundCareSelectedPatientId;
      const response = await WoundCareService.updatePatientChestDrain(patientId, id, data);
      commit('SET_SUCCESS', 'Chest drain updated successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating chest drain');
    }
  },
  async deleteChestDrain({ commit, getters }, id) {
    try {
      const patientId = getters.woundCareSelectedPatientId;
      await WoundCareService.deletePatientChestDrain(patientId, id);
      commit('SET_SUCCESS', 'Chest drain deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting chest drain');
    }
  },
  async createWoundCareClinicalDescription({ commit, getters }, data) {
    try {
      const patientId = getters.woundCareSelectedPatientId;
      const response = await WoundCareService.createWoundCareClinicalDescription(patientId, data);
      commit('SET_SUCCESS', 'Wound care clinical description created successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating wound care clinical description');
    }
  },
  removeError({ commit }) {
    commit('SET_ERROR', null);
  },
  removeSuccess({ commit }) {
    commit('SET_SUCCESS', null);
  }
};

const mutations = {
  SET_WOUND_CARES: (state, woundCares) => {
    state.woundCares = woundCares;
  },
  SET_SELECTED_WOUND_CARE: (state, woundCare) => {
    state.selectedWoundCare = woundCare;
  },
  SET_DRAINS: (state, drains) => {
    state.drains = drains;
  },
  SET_SELECTED_DRAIN: (state, drain) => {
    state.selectedDrain = drain;
  },
  SET_CHEST_DRAINS: (state, chestDrains) => {
    state.chestDrains = chestDrains;
  },
  SET_SELECTED_CHEST_DRAIN: (state, chestDrain) => {
    state.selectedChestDrain = chestDrain;
  },  
  clearWoundCare(state) {
    state.woundCares = null;
    state.selectedWoundCare = null;
    state.drains = null;
    state.selectedDrain = null;
    state.chestDrains = null;
    state.selectedChestDrain = null;
    state.error = null;
    state.success = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
