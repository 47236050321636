import http from '@/http.js';

export class AllergyService {
  static getPatientAllergies(patientId) {
    return http.get(`/api/patients/${patientId}/allergies`);
  }

  static getAllergyDetails(patientId, allergyId) {
    return http.get(`/api/patients/${patientId}/allergies/${allergyId}`);
  }

  static createPatientAllergy(patientId, data) {
    return http.post(`/api/patients/${patientId}/allergies`, JSON.stringify(data));
  }

  static updatePatientAllergy(patientId, allergyId, data) {
    return http.put(`/api/patients/${patientId}/allergies/${allergyId}`, JSON.stringify(data));
  }

  static deletePatientAllergy(patientId, allergyId) {
    return http.delete(`/api/patients/${patientId}/allergies/${allergyId}`);
  }
}
