import http from '@/http.js';

export class PatientService {
  static getPatients() {
    return http.get('/api/patients');
  }

  static getPatientsAllInfo(data) {
    return http.post('/api/patients/allinfo', JSON.stringify(data));
  }

  static getPatientDetails(id) {
    return http.get(`/api/patients/${id}`);
  }

  static createPatient(data) {
    return http.post('/api/patients', JSON.stringify(data));
  }

  static updatePatient(id, data) {
    return http.put(`/api/patients/${id}`, JSON.stringify(data));
  }

  static deletePatient(id) {
    return http.delete(`/api/patients/${id}`);
  }

  static editAdmissionInformation(id, data) {
    return http.post(`/api/patients/${id}/admission-information`, JSON.stringify(data));
  }
}
