import http from '@/http.js';

export class ResearchService {
  static getPatientResearches(patientId, data) {
    return http.post(`/api/patients/${patientId}/research/get`, JSON.stringify(data));
  }

  static getResearchDetails(patientId, researchId) {
    return http.get(`/api/patients/${patientId}/research/${researchId}`);
  }

  static createPatientResearch(patientId, data) {
    return http.post(`/api/patients/${patientId}/research`, JSON.stringify(data));
  }

  static updatePatientResearch(patientId, researchId, data) {
    return http.put(`/api/patients/${patientId}/research/${researchId}`, JSON.stringify(data));
  }

  static deletePatientResearch(patientId, researchId) {
    return http.delete(`/api/patients/${patientId}/research/${researchId}`);
  }
}
