import { ParameterService } from '@/services/parameter.service.js';

const state = {
  parameters: null,
  selectedParameter: null,
  error: null,
  success: null
};

const getters = {
  parameters: state => state.parameters,
  selectedParameter: state => state.selectedParameter,
  parameterError: state => state.error,
  parameterSuccess: state => state.success,
  selectedPatientId: (state, getters, rootState) => {
    return rootState.patient.selectedPatient.id;
  },
  selectedStudentId: (state, getters, rootState) => {
    if (rootState.user.selectedStudent === null) return undefined;
    return rootState.user.selectedStudent.id;
  }
};

const actions = {
  async fetchParameters({ commit, getters }, data) {
    try {
      const studentId = getters.selectedStudentId === undefined ? "0" : getters.selectedStudentId;
      const response = await ParameterService.getPatientParameters(data, {'selected_user': studentId});
      commit('SET_PARAMETERS', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching parameters');
    }
  },
  async createParameter({ commit, getters }, data) {
    try {
      const patientId = getters.selectedPatientId;
      const response = await ParameterService.createPatientParameter(patientId, data);
      commit('SET_SUCCESS', 'Parameter created successfully');
      return response.data
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating parameter');
    }
  },
  async updateParameter({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.selectedPatientId;
      const response = await ParameterService.updatePatientParameter(patientId, id, data);
      commit('SET_SUCCESS', 'Parameter updated successfully');
      return response.data
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating parameter');
    }
  },
  async deleteParameter({ commit, getters }, id) {
    try {
      const patientId = getters.selectedPatientId;
      await ParameterService.deletePatientParameter(patientId, id);
      commit('SET_SUCCESS', 'Parameter deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting parameter');
    }
  },
  removeError({ commit }) {
    commit('SET_ERROR', null);
  },
  removeSuccess({ commit }) {
    commit('SET_SUCCESS', null);
  }
};

const mutations = {
  SET_PARAMETERS: (state, parameters) => {
    state.parameters = parameters;
  },
  SET_SELECTED_PARAMETER: (state, parameter) => {
    state.selectedParameter = parameter;
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
  SET_SUCCESS: (state, success) => {
    state.success = success;
  },
  clearParameter(state) {
    state.parameters = null;
    state.selectedParameter = null;
    state.error = null;
    state.success = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
