import { createApp } from 'vue'
import { encryptStorage } from '@/constants.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue'
import router from './router'
import store from './store'

import './assets/styles/index.css'
import './assets/js/scripts.js'

async function main () {
  if (encryptStorage.getItem('epd-access-token') && !store.getters.user) {
    var response = await store.dispatch('getUser')
    if (typeof response.response === "undefined" && encryptStorage.getItem('epd-p')) {
      if (store.getters.user.group === 'docent' &&  encryptStorage.getItem('epd-su')) {
        await store.dispatch('getStudents')
        await store.dispatch('setSelectedStudentById', encryptStorage.getItem('epd-su'))
      }
      await store.dispatch('fetchPatients')
      await store.dispatch('setSelectedPatientById', encryptStorage.getItem('epd-p'))
    }
  }
  createApp(App).use(store).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
}

main()