import http from '@/http.js';

export class ParameterService {
  static getPatientParameters(patientId, data) {
    return http.post(`/api/patients/${patientId}/parameters/get`, JSON.stringify(data));
  }

  static getParameterDetails(patientId, parameterId) {
    return http.get(`/api/patients/${patientId}/parameters/${parameterId}`);
  }

  static createPatientParameter(patientId, data) {
    return http.post(`/api/patients/${patientId}/parameters`, JSON.stringify(data));
  }

  static updatePatientParameter(patientId, parameterId, data) {
    return http.put(`/api/patients/${patientId}/parameters/${parameterId}`, JSON.stringify(data));
  }

  static deletePatientParameter(patientId, parameterId) {
    return http.delete(`/api/patients/${patientId}/parameters/${parameterId}`);
  }
}
