import { MedicationService } from '@/services/medication.service.js';

const state = {
  medications: null,
  selectedMedication: null,
  error: null,
  success: null
};

const getters = {
  medications: state => state.medications,
  selectedMedication: state => state.selectedMedication,
  medicationError: state => state.error,
  medicationSuccess: state => state.success,
  medicationSelectedPatientId: (state, getters, rootState) => {
    return rootState.patient.selectedPatient.id;
  },
  medicationSelectedStudentId: (state, getters, rootState) => {
    if (rootState.user.selectedStudent === null) return undefined;
    return rootState.user.selectedStudent.id;
  }
};

const actions = {
  async fetchMedications({ commit, getters }, data) {
    try {
      const studentId = getters.selectedStudentId === undefined ? "0" : getters.selectedStudentId;
      const response = await MedicationService.getPatientMedications(data, {'selected_user': studentId});
      commit('SET_MEDICATIONS', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching medications');
    }
  },
  async createMedication({ commit, getters }, data) {
    try {
      const patientId = getters.medicationSelectedPatientId;
      const response = await MedicationService.createPatientMedication(patientId, data);
      commit('SET_SUCCESS', 'Medication report created successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating medication');
    }
  },
  async updateMedication({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.medicationSelectedPatientId;
      const response = await MedicationService.updatePatientMedication(patientId, id, data);
      commit('SET_SUCCESS', 'Medication report updated successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating medication');
    }
  },
  async deleteMedication({ commit, getters }, id) {
    try {
      const patientId = getters.medicationSelectedPatientId;
      await MedicationService.deletePatientMedication(patientId, id);
      commit('SET_SUCCESS', 'Medication report deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting medication');
    }
  },
  async createMedicationDate({ commit, getters }, data) {
    try {
      const patientId = getters.medicationSelectedPatientId;
      const response = await MedicationService.createPatientMedicationDate(patientId, data);
      commit('SET_SUCCESS', 'Medication date created successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating medication date');
    }
  },
  async updateMedicationDate({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.medicationSelectedPatientId;
      const response = await MedicationService.updatePatientMedicationDate(patientId, id, data);
      commit('SET_SUCCESS', 'Medication date updated successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating medication date');
    }
  },
  async deleteMedicationDate({ commit, getters }, id) {
    try {
      const patientId = getters.medicationSelectedPatientId;
      await MedicationService.deletePatientMedicationDate(patientId, id);
      commit('SET_SUCCESS', 'Medication date deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting medication date');
    }
  },
  removeError({ commit }) {
    commit('SET_ERROR', null);
  },
  removeSuccess({ commit }) {
    commit('SET_SUCCESS', null);
  }
};

const mutations = {
  SET_MEDICATIONS: (state, medications) => {
    state.medications = medications;
  },
  SET_SELECTED_MEDICATION: (state, medication) => {
    state.selectedMedication = medication;
  },
  clearMedication(state) {
    state.medications = null;
    state.selectedMedication = null;
    state.error = null;
    state.success = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
