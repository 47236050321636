import { ADLService } from '@/services/adl.service.js';

const state = {
  hygienes: null,
  selectedHygiene: null,
  mobilityInstallationRoom: null,
  selectedMobililtyInstallationRoom: null,
  mobilityDecubitus: null,
  selectedMobilityDecubitus: null,
  nutrition: null,
  selectedNutrition: null,
  urinaryExcretion: null,
  selectedUrinaryExcretion: null,
  fecalExcretion: null,
  selectedFecalExcretion: null,
  error: null,
  success: null
};

const getters = {
  hygienes: state => state.hygienes,
  selectedHygiene: state => state.selectedHygiene,
  mobilityInstallationRoom: state => state.mobilityInstallationRoom,
  selectedMobilityInstallationRoom: state => state.selectedMobilityInstallationRoom,
  mobilityDecubitus: state => state.mobilityDecubitus,
  selectedMobilityDecubitus: state => state.selectedMobilityDecubitus,
  nutrition: state => state.nutrition,
  selectedNutrition: state => state.selectedNutrition,
  urinaryExcretion: state => state.urinaryExcretion,
  selectedUrinaryExcretion: state => state.selectedUrinaryExcretion,
  fecalExcretion: state => state.fecalExcretion,
  selectedFecalExcretion: state => state.selectedFecalExcretion,
  hygieneError: state => state.error,
  hygieneSuccess: state => state.success,
  ADLSelectedPatientId: (state, getters, rootState) => {
    return rootState.patient.selectedPatient.id;
  },
  ADLSelectedStudentId: (state, getters, rootState) => {
    if (rootState.user.selectedStudent === null) return undefined;
    return rootState.user.selectedStudent.id;
  }
};

const actions = {
  async fetchHygienes({ commit, getters }, data) {
    try {
      const studentId = getters.ADLSelectedStudentId === undefined ? "0" : getters.ADLSelectedStudentId;
      const response = await ADLService.getPatientHygienes(data, {'selected_user': studentId});
      commit('SET_HYGIENES', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching hygienes');
    }
  },
  async createHygiene({ commit, getters }, data) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      const response = await ADLService.createPatientHygiene(patientId, data);
      commit('SET_SUCCESS', 'Hygiene created successfully');
      return response.data
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating hygiene');
    }
  },
  async updateHygiene({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      const response = await ADLService.updatePatientHygiene(patientId, id, data);
      commit('SET_SUCCESS', 'Hygiene updated successfully');
      return response.data
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating hygiene');
    }
  },
  async deleteHygiene({ commit, getters }, id) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      await ADLService.deletePatientHygiene(patientId, id);
      commit('SET_SUCCESS', 'Hygiene deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting hygiene');
    }
  },
  async fetchMobilityInstallationRoom({ commit, getters }, data) {
    try {
      const studentId = getters.ADLSelectedStudentId === undefined ? "0" : getters.ADLSelectedStudentId;
      const response = await ADLService.getPatientMobilityInstallationRoom(data, {'selected_user': studentId});
      commit('SET_MOBILITY_INSTALLATION_ROOM', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching mobility installation room records');
    }
  },  
  async createMobilityInstallationRoom({ commit, getters }, data) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      const response = await ADLService.createPatientMobilityInstallationRoom(patientId, data);
      commit('SET_SUCCESS', 'Mobility installation room record created successfully');
      return response.data
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating mobility installation room record');
    }
  },  
  async updateMobilityInstallationRoom({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      const response = await ADLService.updatePatientMobilityInstallationRoom(patientId, id, data);
      commit('SET_SUCCESS', 'Mobility installation room record updated successfully');
      return response.data
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating mobility installation room record');
    }
  },  
  async deleteMobilityInstallationRoom({ commit, getters }, id) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      await ADLService.deletePatientMobilityInstallationRoom(patientId, id);
      commit('SET_SUCCESS', 'Mobility installation room record deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting mobility installation room record');
    }
  },
  async fetchMobilityDecubitus({ commit, getters }, data) {
    try {
      const studentId = getters.ADLSelectedStudentId === undefined ? "0" : getters.ADLSelectedStudentId;
      const response = await ADLService.getPatientMobilityDecubitus(data, {'selected_user': studentId});
      commit('SET_MOBILITY_DECUBITUS', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching mobility decubitus records');
    }
  },
  async createMobilityDecubitus({ commit, getters }, data) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      const response = await ADLService.createPatientMobilityDecubitus(patientId, data);
      commit('SET_SUCCESS', 'Mobility decubitus record created successfully');
      return response.data
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating mobility decubitus record');
    }
  },
  async updateMobilityDecubitus({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      const response = await ADLService.updatePatientMobilityDecubitus(patientId, id, data);
      commit('SET_SUCCESS', 'Mobility decubitus record updated successfully');
      return response.data
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating mobility decubitus record');
    }
  },
  async deleteMobilityDecubitus({ commit, getters }, id) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      await ADLService.deletePatientMobilityDecubitus(patientId, id);
      commit('SET_SUCCESS', 'Mobility decubitus record deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting mobility decubitus record');
    }
  },
  async fetchNutrition({ commit, getters }, data) {
    try {
      const studentId = getters.ADLSelectedStudentId === undefined ? "0" : getters.ADLSelectedStudentId;
      const response = await ADLService.getPatientNutrition(data, {'selected_user': studentId});
      commit('SET_NUTRITION', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching nutrition records');
    }
  },
  async createNutrition({ commit, getters }, data) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      const response = await ADLService.createPatientNutrition(patientId, data);
      commit('SET_SUCCESS', 'Nutrition record created successfully');
      return response.data
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating nutrition record');
    }
  },
  async updateNutrition({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      const response = await ADLService.updatePatientNutrition(patientId, id, data);
      commit('SET_SUCCESS', 'Nutrition record updated successfully');
      return response.data
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating nutrition record');
    }
  },
  async deleteNutrition({ commit, getters }, id) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      await ADLService.deletePatientNutrition(patientId, id);
      commit('SET_SUCCESS', 'Nutrition record deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting nutrition record');
    }
  },
  async fetchUrinaryExcretion({ commit, getters }, data) {
    try {
      const studentId = getters.ADLSelectedStudentId === undefined ? "0" : getters.ADLSelectedStudentId;
      const response = await ADLService.getPatientUrinaryExcretion(data, {'selected_user': studentId});
      commit('SET_URINARY_EXCRETION', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching urinary excretion records');
    }
  },
  async createUrinaryExcretion({ commit, getters }, data) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      const response = await ADLService.createPatientUrinaryExcretion(patientId, data);
      commit('SET_SUCCESS', 'Urinary excretion record created successfully');
      return response.data
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating urinary excretion record');
    }
  },
  async updateUrinaryExcretion({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      const response = await ADLService.updatePatientUrinaryExcretion(patientId, id, data);
      commit('SET_SUCCESS', 'Urinary excretion record updated successfully');
      return response.data
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating urinary excretion record');
    }
  },
  async deleteUrinaryExcretion({ commit, getters }, id) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      await ADLService.deletePatientUrinaryExcretion(patientId, id);
      commit('SET_SUCCESS', 'Urinary excretion record deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting urinary excretion record');
    }
  },
  async fetchFecalExcretion({ commit, getters }, data) {
    try {
      const studentId = getters.ADLSelectedStudentId === undefined ? "0" : getters.ADLSelectedStudentId;
      const response = await ADLService.getPatientFecalExcretion(data, {'selected_user': studentId});
      commit('SET_FECAL_EXCRETION', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching fecal excretion records');
    }
  },
  async createFecalExcretion({ commit, getters }, data) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      const response = await ADLService.createPatientFecalExcretion(patientId, data);
      commit('SET_SUCCESS', 'Fecal excretion record created successfully');
      return response.data
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating fecal excretion record');
    }
  },
  async updateFecalExcretion({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      const response = await ADLService.updatePatientFecalExcretion(patientId, id, data);
      commit('SET_SUCCESS', 'Fecal excretion record updated successfully');
      return response.data
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating fecal excretion record');
    }
  },
  async deleteFecalExcretion({ commit, getters }, id) {
    try {
      const patientId = getters.ADLSelectedPatientId;
      await ADLService.deletePatientFecalExcretion(patientId, id);
      commit('SET_SUCCESS', 'Fecal excretion record deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting fecal excretion record');
    }
  },
  removeError({ commit }) {
    commit('SET_ERROR', null);
  },
  removeSuccess({ commit }) {
    commit('SET_SUCCESS', null);
  }
};

const mutations = {
  SET_HYGIENES: (state, hygienes) => {
    state.hygienes = hygienes;
  },
  SET_SELECTED_HYGIENE: (state, hygiene) => {
    state.selectedHygiene = hygiene;
  },
  SET_MOBILITY_INSTALLATION_ROOM: (state, data) => {
    state.mobilityInstallationRoom = data;
  },
  SET_SELECTED_MOBILITY_INSTALLATION_ROOM: (state, hygiene) => {
    state.selectedHygiene = hygiene;
  },
  SET_MOBILITY_DECUBITUS: (state, data) => {
    state.mobilityDecubitus = data;
  },
  SET_SELECTED_MOBILITY_DECUBITUS: (state, hygiene) => {
    state.selectedHygiene = hygiene;
  },
  SET_NUTRITION: (state, data) => {
    state.nutrition = data;
  },
  SET_SELECTED_NUTRITION: (state, hygiene) => {
    state.selectedHygiene = hygiene;
  },
  SET_URINARY_EXCRETION: (state, data) => {
    state.urinaryExcretion = data;
  },
  SET_SELECTED_URINARY_EXCRETION: (state, hygiene) => {
    state.selectedHygiene = hygiene;
  },
  SET_FECAL_EXCRETION: (state, data) => {
    state.fecalExcretion = data;
  },
  SET_SELECTED_FECAL_EXCRETION: (state, hygiene) => {
    state.selectedHygiene = hygiene;
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
  SET_SUCCESS: (state, success) => {
    state.success = success;
  },
  clearADL(state) {
    state.hygienes = null;
    state.selectedHygiene = null;
    state.mobilityInstallationRoom = null;
    state.selectedMobilityInstallationRoom = null;
    state.mobilityDecubitus = null;
    state.selectedMobilityDecubitus = null;
    state.nutrition = null;
    state.selectedNutrition = null;
    state.urinaryExcretion = null;
    state.selectedUrinaryExcretion = null;
    state.fecalExcretion = null;
    state.selectedFecalExcretion = null;
    state.medications = null;
    state.selectedMedication = null;
    state.error = null;
    state.success = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
