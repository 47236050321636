import { AuthService } from '@/services/auth.service.js'
import { encryptStorage } from '@/constants.js'
import store from '../index.js';

const state = {
  user: null,
  accessToken: encryptStorage.getItem('epd-access-token') ?? null,
  error: null,
  success: null
}

const getters = {
  user: state => state.user,
  accessToken: state => state.accessToken,
  error: state => state.error,
  success: state => state.success
}

const actions = {
  async login ({ commit }, data) {
    return await AuthService.login(data).then(response => {
      if (typeof response.response === "undefined") {
        commit('SET_AUTH', response.data)
        commit('SET_USER', response.data.user)
      } else {
        commit('SET_ERROR', 'Ongeldige inloggegevens')
        return 'Ongeldige inloggegevens'
      }
    })
  },
  async sso ({ commit }, data) {
        commit('SET_OAUTH', data.oauth_sso_code)
  },
  async forgotPassword ({ commit }, data) {
    return await AuthService.forgotPassword(data).then(response => {
      if (typeof response.response === "undefined") {
        commit('SET_SUCCESS', 'Er is een e-mail verstuurd naar het opgegeven e-mailadres')
      } else {
        commit('SET_ERROR', 'Er is iets misgegaan')
        return 'Er is iets misgegaan'
      }
    })
  },
  async resetPassword ({ commit }, data) {
    return await AuthService.resetPassword(data).then(response => {
      if (typeof response.response === "undefined") {
        commit('SET_SUCCESS', 'Het wachtwoord is succesvol gewijzigd')
      } else {
        commit('SET_ERROR', 'Er is iets misgegaan')
        return 'Er is iets misgegaan'
      }
    })
  },
  async getUser ({ commit }) {
    return await AuthService.getUser().then(response => {
      if (typeof response.response === "undefined") {
        commit('SET_USER', response.data.user)
      }
      return response
    })
  },
  async logout ({ commit }) {
    commit('CLEAR_AUTH')
  },
  removeError ({ commit }) {
    commit('SET_ERROR', '')
  },
  removeSuccess ({ commit }) {
    commit('SET_SUCCESS', '')
  }
}

const mutations = {
  SET_AUTH: (state, payload) => {
    encryptStorage.setItem('epd-access-token', payload.access_token.toString())
    state.accessToken = payload.access_token.toString()
  },
  SET_OAUTH: (state, payload) => {
    encryptStorage.setItem('epd-access-token', payload.toString())
    state.accessToken = payload.toString()
  },
  SET_USER: (state, payload) => {
    state.user = payload
  },
  SET_ERROR: (state, payload) => {
    state.error = payload
  },
  SET_SUCCESS: (state, payload) => {
    state.success = payload
  },
  CLEAR_AUTH: (state) => {
    encryptStorage.removeItem('epd-access-token')
    state.user = null
    state.accessToken = null
    state.error = null;
    state.success = null;
    store.commit('clearADL');
    store.commit('clearCatheterCare');
    store.commit('clearLog');
    store.commit('clearMedication');
    store.commit('clearParameter');
    store.commit('clearPatient');
    store.commit('clearReport');
    store.commit('clearResearch');
    store.commit('clearSymptomManagement');
    store.commit('clearTenant');
    store.commit('clearUser');
    store.commit('clearWoundCare');
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
