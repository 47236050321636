import http from '@/http.js';

export class WoundCareService {
  static getPatientWoundCare(patientId, data) {
    return http.post(`/api/patients/${patientId}/wound_care/wound_care/get`, JSON.stringify(data));
  }

  static getWoundCareDetails(patientId, woundCareId) {
    return http.get(`/api/patients/${patientId}/wound_care/wound_care/${woundCareId}`);
  }

  static createPatientWoundCare(patientId, data) {
    return http.post(`/api/patients/${patientId}/wound_care/wound_care`, JSON.stringify(data));
  }

  static updatePatientWoundCare(patientId, woundCareId, data) {
    return http.put(`/api/patients/${patientId}/wound_care/wound_care/${woundCareId}`, JSON.stringify(data));
  }

  static deletePatientWoundCare(patientId, woundCareId) {
    return http.delete(`/api/patients/${patientId}/wound_care/wound_care/${woundCareId}`);
  }

  static getPatientDrain(patientId, data) {
    return http.post(`/api/patients/${patientId}/wound_care/drain/get`, JSON.stringify(data));
  }

  static getDrainDetails(patientId, drainId) {
    return http.get(`/api/patients/${patientId}/wound_care/drain/${drainId}`);
  }

  static createPatientDrain(patientId, data) {
    return http.post(`/api/patients/${patientId}/wound_care/drain`, JSON.stringify(data));
  }

  static updatePatientDrain(patientId, drainId, data) {
    return http.put(`/api/patients/${patientId}/wound_care/drain/${drainId}`, JSON.stringify(data));
  }

  static deletePatientDrain(patientId, drainId) {
    return http.delete(`/api/patients/${patientId}/wound_care/drain/${drainId}`);
  }

  static getPatientChestDrain(patientId, data) {
    return http.post(`/api/patients/${patientId}/wound_care/chest_drain/get`, JSON.stringify(data));
  }

  static getChestDrainDetails(patientId, chestDrainId) {
    return http.get(`/api/patients/${patientId}/wound_care/chest_drain/${chestDrainId}`);
  }

  static createPatientChestDrain(patientId, data) {
    return http.post(`/api/patients/${patientId}/wound_care/chest_drain`, JSON.stringify(data));
  }

  static updatePatientChestDrain(patientId, chestDrainId, data) {
    return http.put(`/api/patients/${patientId}/wound_care/chest_drain/${chestDrainId}`, JSON.stringify(data));
  }

  static deletePatientChestDrain(patientId, chestDrainId) {
    return http.delete(`/api/patients/${patientId}/wound_care/chest_drain/${chestDrainId}`);
  }

  static createWoundCareClinicalDescription(patientId, data) {
    return http.post(`/api/patients/${patientId}/wound_care/wound_care/clinical_description`, JSON.stringify(data));
  }
}
