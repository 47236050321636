import { LogService } from '@/services/log.service.js';

const state = {
  logs: null,
  selectedLog: null
};

const getters = {
  logs: state => state.logs,
  logError: state => state.error
};

const actions = {
  async fetchLogs({ commit }) {
    try {
      const response = await LogService.getLogs();
      commit('SET_LOGS', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching logs');
    }
  }
};

const mutations = {
  SET_LOGS: (state, logs) => {
    state.logs = logs;
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
  clearLog(state) {
    state.logs = null;
    state.selectedLog = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
