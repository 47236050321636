import http from '@/http.js';

export class ContactPersonService {
  static getPatientContactPersons(patientId) {
    return http.get(`/api/patients/${patientId}/contact-persons`);
  }

  static getContactPersonDetails(patientId, contact_persons_id) {
    return http.get(`/api/patients/${patientId}/contact-persons/${contact_persons_id}`);
  }

  static createPatientContactPerson(patientId, data) {
    return http.post(`/api/patients/${patientId}/contact-persons`, JSON.stringify(data));
  }

  static updatePatientContactPerson(patientId, contact_persons_id, data) {
    return http.put(`/api/patients/${patientId}/contact-persons/${contact_persons_id}`, JSON.stringify(data));
  }

  static deletePatientContactPerson(patientId, contact_persons_id) {
    return http.delete(`/api/patients/${patientId}/contact-persons/${contact_persons_id}`);
  }
}
