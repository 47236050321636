import { ReportService } from '@/services/report.service.js';

const state = {
  medicalReports: null,
  selectedMedicalReport: null,
  nursingReports: null,
  selectedNursingReport: null,
  error: null,
  success: null
};

const getters = {
  medicalReports: state => state.medicalReports,
  selectedMedicalReport: state => state.selectedMedicalReport,
  nursingReports: state => state.nursingReports,
  selectedNursingReport: state => state.selectedNursingReport,
  reportError: state => state.error,
  reportSuccess: state => state.success,
  reportSelectedPatientId: (state, getters, rootState) => {
    return rootState.patient.selectedPatient.id;
  },
  reportSelectedStudentId: (state, getters, rootState) => {
    if (rootState.user.selectedStudent === null) return undefined;
    return rootState.user.selectedStudent.id;
  }
};

const actions = {
  async fetchMedicalReports({ commit, getters }, data) {
    try {
      const studentId = getters.reportSelectedStudentId === undefined ? "0" : getters.reportSelectedStudentId;
      const response = await ReportService.getPatientMedicalReports(data, {'selected_user': studentId});
      commit('SET_MEDICAL_REPORTS', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching medical reports');
    }
  },
  async createMedicalReport({ commit, getters }, data) {
    try {
      const patientId = getters.reportSelectedPatientId;
      const response = await ReportService.createPatientMedicalReport(patientId, data);
      commit('SET_SUCCESS', 'Medical report created successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating medical report');
    }
  },
  async updateMedicalReport({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.reportSelectedPatientId;
      const response = await ReportService.updatePatientMedicalReport(patientId, id, data);
      commit('SET_SUCCESS', 'Medical report updated successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating medical report');
    }
  },
  async deleteMedicalReport({ commit, getters }, id) {
    try {
      const patientId = getters.woundCareSelectedPatientId;
      await ReportService.deletePatientMedicalReport(patientId, id);
      commit('SET_SUCCESS', 'Medical report deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting medical report');
    }
  },
  async fetchNursingReports({ commit, getters }, data) {
    try {
      const studentId = getters.reportSelectedStudentId === undefined ? "0" : getters.reportSelectedStudentId;
      const response = await ReportService.getPatientNursingReports(data, {'selected_user': studentId});
      commit('SET_NURSING_REPORTS', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while nursing reports');
    }
  },
  async createNursingReport({ commit, getters }, data) {
    try {
      const patientId = getters.woundCareSelectedPatientId;
      const response = await ReportService.createPatientNursingReport(patientId, data);
      commit('SET_SUCCESS', 'Nursing report created successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating nursing report');
    }
  },
  async updateNursingReport({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.woundCareSelectedPatientId;
      const response = await ReportService.updatePatientNursingReport(patientId, id, data);
      commit('SET_SUCCESS', 'Nursing report updated successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating nursing report');
    }
  },
  async deleteNursingReport({ commit, getters }, id) {
    try {
      const patientId = getters.woundCareSelectedPatientId;
      await ReportService.deletePatientNursingReport(patientId, id);
      commit('SET_SUCCESS', 'Nursing report deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting nursing report');
    }
  },
  removeError({ commit }) {
    commit('SET_ERROR', null);
  },
  removeSuccess({ commit }) {
    commit('SET_SUCCESS', null);
  }
};

const mutations = {
  SET_MEDICAL_REPORTS: (state, medicalReports) => {
    state.medicalReports = medicalReports;
  },
  SET_SELECTED_MEDICAL_REPORT: (state, medicalReport) => {
    state.selectedMedicalReport = medicalReport;
  },
  SET_NURSING_REPORTS: (state, nursingReports) => {
    state.nursingReports = nursingReports;
  },
  SET_SELECTED_NURSING_REPORT: (state, nursingReport) => {
    state.selectedNursingReport = nursingReport;
  },
  clearReport(state) {
    state.medicalReports = null;
    state.selectedMedicalReport = null;
    state.nursingReports = null;
    state.selectedNursingReport = null;
    state.error = null;
    state.success = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
