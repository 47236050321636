import { UserService } from '@/services/user.service.js';
import { encryptStorage } from '@/constants.js'
import store from '../index.js';

const state = {
  users: null,
  selectedUser: null,
  students: null,
  selectedStudent: null,
  error: null,
  success: null,
  navDropdown: [false, false, false]
};

const getters = {
  users: state => state.users,
  selectedUser: state => state.selectedUser,
  students: state => state.students,
  selectedStudent: state => state.selectedStudent,
  userError: state => state.error,
  userSuccess: state => state.success,
  navDropdown: state => state.navDropdown
};

const actions = {
  async fetchUsers({ commit }) {
    try {
      const response = await UserService.getUsers();
      commit('SET_USERS', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching users');
    }
  },
  async createUser({ commit }, data) {
    try {
      await UserService.createUser(data);
      commit('SET_SUCCESS', 'User created successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating user');
    }
  },
  async updateUser({ commit }, { id, data }) {
    try {
      await UserService.updateUser(id, data);
      commit('SET_SUCCESS', 'User updated successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating user');
    }
  },
  async deleteUser({ commit }, id) {
    try {
      await UserService.deleteUser(id);
      commit('SET_SUCCESS', 'User deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting user');
    }
  },
  async getStudents({ commit }) {
    try {
      const response = await UserService.getStudents();
      commit('SET_STUDENTS', response.data.students);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching students');
    }
  },
  async deleteStudents({ commit }) {
    try {
      await UserService.deleteStudents();
      commit('SET_SUCCESS', 'Students deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting students');
    }
  },
  async deleteStudentsData({ commit }) {
    try {
      await UserService.deleteStudentsData();
      commit('SET_SUCCESS', 'Students data deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting students data');
    }
  },
  setSelectedStudent({ commit }, student) {
    commit('SET_SELECTED_STUDENT', student);
  },
  setSelectedStudentById({ commit, state }, id) {
    const student = state.students.find((student) => student.id === id.toString());
    if (student) {
      commit('SET_SELECTED_STUDENT', student);
    }
  },
  removeError({ commit }) {
    commit('SET_ERROR', null);
  },
  removeSuccess({ commit }) {
    commit('SET_SUCCESS', null);
  },
  setNavDropdown({ commit }, index) {
    commit('SET_NAV_DROPDOWN', index);
  },
};

const mutations = {
  SET_USERS: (state, users) => {
    state.users = users;
  },
  SET_SELECTED_USER: (state, user) => {
    state.selectedUser = user;
  },
  SET_STUDENTS: (state, students) => {
    state.students = students;
  },
  SET_SELECTED_STUDENT: (state, student) => {
    state.selectedStudent = student;
    if (student !== null) encryptStorage.setItem('epd-su', student.id)
    else encryptStorage.removeItem('epd-su')
    store.commit('clearADL');
    store.commit('clearCatheterCare');
    store.commit('clearMedication');
    store.commit('clearParameter');
    store.commit('clearReport');
    store.commit('clearResearch');
    store.commit('clearSymptomManagement');
    store.commit('clearWoundCare');
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
  SET_SUCCESS: (state, success) => {
    state.success = success;
  },
  SET_NAV_DROPDOWN: (state, index) => {
    state.navDropdown[index] = !state.navDropdown[index];
  },
  clearTenant(state) {
    state.users = null;
    state.selectedUser = null;
    state.students = null;
    state.selectedStudent = null;
    state.error = null;
    state.success = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
