import http from '@/http.js';

export class SymptomManagementService {
  static getPatientNauseaVomiting(patientId, data) {
    return http.post(`/api/patients/${patientId}/symptom_management/nausea_vomiting/get`, JSON.stringify(data));
  }

  static getNauseaVomitingDetails(patientId, nauseaVomitingId) {
    return http.get(`/api/patients/${patientId}/symptom_management/nausea_vomiting/${nauseaVomitingId}`);
  }

  static createPatientNauseaVomiting(patientId, data) {
    return http.post(`/api/patients/${patientId}/symptom_management/nausea_vomiting`, JSON.stringify(data));
  }

  static updatePatientNauseaVomiting(patientId, nauseaVomitingId, data) {
    return http.put(`/api/patients/${patientId}/symptom_management/nausea_vomiting/${nauseaVomitingId}`, JSON.stringify(data));
  }

  static deletePatientNauseaVomiting(patientId, nauseaVomitingId) {
    return http.delete(`/api/patients/${patientId}/symptom_management/nausea_vomiting/${nauseaVomitingId}`);
  }

  static getPatientConscience(patientId, data) {
    return http.post(`/api/patients/${patientId}/symptom_management/conscience/get`, JSON.stringify(data));
  }
  
  static getConscienceDetails(patientId, conscienceId) {
    return http.get(`/api/patients/${patientId}/symptom_management/conscience/${conscienceId}`);
  }
  
  static createPatientConscience(patientId, data) {
    return http.post(`/api/patients/${patientId}/symptom_management/conscience`, JSON.stringify(data));
  }
  
  static updatePatientConscience(patientId, conscienceId, data) {
    return http.put(`/api/patients/${patientId}/symptom_management/conscience/${conscienceId}`, JSON.stringify(data));
  }
  
  static deletePatientConscience(patientId, conscienceId) {
    return http.delete(`/api/patients/${patientId}/symptom_management/conscience/${conscienceId}`);
  }
  
  static getPatientPain(patientId, data) {
    return http.post(`/api/patients/${patientId}/symptom_management/pain/get`, JSON.stringify(data));
  }
  
  static getPainDetails(patientId, painId) {
    return http.get(`/api/patients/${patientId}/symptom_management/pain/${painId}`);
  }
  
  static createPatientPain(patientId, data) {
    return http.post(`/api/patients/${patientId}/symptom_management/pain`, JSON.stringify(data));
  }
  
  static updatePatientPain(patientId, painId, data) {
    return http.put(`/api/patients/${patientId}/symptom_management/pain/${painId}`, JSON.stringify(data));
  }
  
  static deletePatientPain(patientId, painId) {
    return http.delete(`/api/patients/${patientId}/symptom_management/pain/${painId}`);
  }
  
}
