import http from '@/http.js';

export class ReportService {
  static getPatientMedicalReports(patientId, data) {
    return http.post(`/api/patients/${patientId}/reports/medical/get`, JSON.stringify(data));
  }

  static getMedicalReportDetails(patientId, medicalReportId) {
    return http.get(`/api/patients/${patientId}/reports/medical/${medicalReportId}`);
  }

  static createPatientMedicalReport(patientId, data) {
    return http.post(`/api/patients/${patientId}/reports/medical`, JSON.stringify(data));
  }

  static updatePatientMedicalReport(patientId, medicalReportId, data) {
    return http.put(`/api/patients/${patientId}/reports/medical/${medicalReportId}`, JSON.stringify(data));
  }

  static deletePatientMedicalReport(patientId, medicalReportId) {
    return http.delete(`/api/patients/${patientId}/reports/medical/${medicalReportId}`);
  }

  static getPatientNursingReports(patientId, data) {
    return http.post(`/api/patients/${patientId}/reports/nursing/get`, JSON.stringify(data));
  }

  static getNursingReportDetails(patientId, nursingReportId) {
    return http.get(`/api/patients/${patientId}/reports/nursing/${nursingReportId}`);
  }

  static createPatientNursingReport(patientId, data) {
    return http.post(`/api/patients/${patientId}/reports/nursing`, JSON.stringify(data));
  }

  static updatePatientNursingReport(patientId, nursingReportId, data) {
    return http.put(`/api/patients/${patientId}/reports/nursing/${nursingReportId}`, JSON.stringify(data));
  }

  static deletePatientNursingReport(patientId, nursingReportId) {
    return http.delete(`/api/patients/${patientId}/reports/nursing/${nursingReportId}`);
  }
}
