import { SymptomManagementService } from '@/services/symptom_management.service.js';

const state = {
  nauseaVomiting: null,
  selectedNauseaVomiting: null,
  conscience: null,
  selectedConscience: null,
  pain: null,
  selectedPain: null,
  error: null,
  success: null
};

const getters = {
  nauseaVomiting: state => state.nauseaVomiting,
  selectedNauseaVomiting: state => state.selectedNauseaVomiting,
  conscience: state => state.conscience,
  selectedConscience: state => state.selectedConscience,
  pain: state => state.pain,
  selectedPain: state => state.selectedPain,
  nauseaVomitingError: state => state.error,
  nauseaVomitingSuccess: state => state.success,
  nauseaVomitingSelectedPatientId: (state, getters, rootState) => {
    return rootState.patient.selectedPatient.id;
  },
  nauseaVomitingSelectedStudentId: (state, getters, rootState) => {
    if (rootState.user.selectedStudent === null) return undefined;
    return rootState.user.selectedStudent.id;
  }
};

const actions = {
  async fetchNauseaVomiting({ commit, getters }, data) {
    try {
      const studentId = getters.nauseaVomitingSelectedStudentId === undefined ? "0" : getters.nauseaVomitingSelectedStudentId;
      const response = await SymptomManagementService.getPatientNauseaVomiting(data, {'selected_user': studentId});
      commit('SET_NAUSEA_VOMITING', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching nausea vomiting');
    }
  },
  async createNauseaVomiting({ commit, getters }, data) {
    try {
      const patientId = getters.nauseaVomitingSelectedPatientId;
      const response = await SymptomManagementService.createPatientNauseaVomiting(patientId, data);
      commit('SET_SUCCESS', 'Nausea vomiting created successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating nausea vomiting');
    }
  },
  async updateNauseaVomiting({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.nauseaVomitingSelectedPatientId;
      const response = await SymptomManagementService.updatePatientNauseaVomiting(patientId, id, data);
      commit('SET_SUCCESS', 'Nausea vomiting updated successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating nausea vomiting');
    }
  },
  async deleteNauseaVomiting({ commit, getters }, id) {
    try {
      const patientId = getters.nauseaVomitingSelectedPatientId;
      await SymptomManagementService.deletePatientNauseaVomiting(patientId, id);
      commit('SET_SUCCESS', 'Nausea vomiting deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting nausea vomiting');
    }
  },
  async fetchConscience({ commit, getters }, data) {
    try {
      const studentId = getters.nauseaVomitingSelectedStudentId === undefined ? "0" : getters.nauseaVomitingSelectedStudentId;
      const response = await SymptomManagementService.getPatientConscience(data, {'selected_user': studentId});
      commit('SET_CONSCIENCE', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching conscience');
    }
  },
  async createConscience({ commit, getters }, data) {
    try {
      const patientId = getters.nauseaVomitingSelectedPatientId;
      const response = await SymptomManagementService.createPatientConscience(patientId, data);
      commit('SET_SUCCESS', 'Conscience created successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating conscience');
    }
  },
  async updateConscience({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.nauseaVomitingSelectedPatientId;
      const response = await SymptomManagementService.updatePatientConscience(patientId, id, data);
      commit('SET_SUCCESS', 'Conscience updated successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating conscience');
    }
  },
  async deleteConscience({ commit, getters }, id) {
    try {
      const patientId = getters.nauseaVomitingSelectedPatientId;
      await SymptomManagementService.deletePatientConscience(patientId, id);
      commit('SET_SUCCESS', 'Conscience deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting conscience');
    }
  },
  async fetchPain({ commit, getters }, data) {
    try {
      const studentId = getters.nauseaVomitingSelectedStudentId === undefined ? "0" : getters.nauseaVomitingSelectedStudentId;
      const response = await SymptomManagementService.getPatientPain(data, {'selected_user': studentId});
      commit('SET_PAIN', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching pain');
    }
  },
  async createPain({ commit, getters }, data) {
    try {
      const patientId = getters.nauseaVomitingSelectedPatientId;
      const response = await SymptomManagementService.createPatientPain(patientId, data);
      commit('SET_SUCCESS', 'Pain created successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating pain');
    }
  },
  async updatePain({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.nauseaVomitingSelectedPatientId;
      const response = await SymptomManagementService.updatePatientPain(patientId, id, data);
      commit('SET_SUCCESS', 'Pain updated successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating pain');
    }
  },
  async deletePain({ commit, getters }, id) {
    try {
      const patientId = getters.nauseaVomitingSelectedPatientId;
      await SymptomManagementService.deletePatientPain(patientId, id);
      commit('SET_SUCCESS', 'Pain deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting pain');
    }
  },
  removeError({ commit }) {
    commit('SET_ERROR', null);
  },
  removeSuccess({ commit }) {
    commit('SET_SUCCESS', null);
  }
};

const mutations = {
  SET_NAUSEA_VOMITING: (state, nauseaVomiting) => {
    state.nauseaVomiting = nauseaVomiting;
  },
  SET_SELECTED_CATHETER_CARE: (state, nauseaVomiting) => {
    state.selectedNauseaVomiting = nauseaVomiting;
  },
  SET_CONSCIENCE: (state, conscience) => {
    state.conscience = conscience;
  },
  SET_SELECTED_CONSCIENCE: (state, selectedConscience) => {
    state.selectedConscience = selectedConscience;
  },
  SET_PAIN: (state, pain) => {
    state.pain = pain;
  },
  SET_SELECTED_PAIN: (state, selectedPain) => {
    state.selectedPain = selectedPain;
  },
  clearSymptomManagement(state) {
    state.nauseaVomiting = null;
    state.selectedNauseaVomiting = null;
    state.conscience = null;
    state.selectedConscience = null;
    state.pain = null;
    state.selectedPain = null;
    state.error = null;
    state.success = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
