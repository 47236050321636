import { createStore } from 'vuex'
import auth from './modules/auth'
import tenant from './modules/tenant'
import user from './modules/user'
import patient from './modules/patient'
import log from './modules/log'
import parameter from './modules/parameter'
import adl from './modules/adl'
import catheterCare from './modules/catheter_care'
import symptomManagement from './modules/symptom_management'
import woundCare from './modules/wound_care'
import reports from './modules/reports'
import research from './modules/research'
import medication from './modules/medication'

const store = createStore({
  modules: {
    auth,
    tenant,
    user,
    patient,
    log,
    parameter,
    adl,
    catheterCare,
    symptomManagement,
    woundCare,
    reports,
    research,
    medication
  }
})

export default store;