import { CatheterCareService } from '@/services/catheter_care.service.js';

const state = {
  catheterCares: null,
  selectedCatheterCare: null,
  error: null,
  success: null
};

const getters = {
  catheterCares: state => state.catheterCares,
  selectedCatheterCare: state => state.selectedCatheterCare,
  catheterCareError: state => state.error,
  catheterCareSuccess: state => state.success,
  catheterCareSelectedPatientId: (state, getters, rootState) => {
    return rootState.patient.selectedPatient.id;
  },
  catheterCareSelectedStudentId: (state, getters, rootState) => {
    if (rootState.user.selectedStudent === null) return undefined;
    return rootState.user.selectedStudent.id;
  }
};

const actions = {
  async fetchCatheterCares({ commit, getters }, data) {
    try {
      const studentId = getters.catheterCareSelectedStudentId === undefined ? "0" : getters.catheterCareSelectedStudentId;
      const response = await CatheterCareService.getPatientCatheterCares(data, {'selected_user': studentId});
      commit('SET_CATHETER_CARES', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching catheter cares');
    }
  },
  async createCatheterCare({ commit, getters }, data) {
    try {
      const patientId = getters.catheterCareSelectedPatientId;
      const response = await CatheterCareService.createPatientCatheterCare(patientId, data);
      commit('SET_SUCCESS', 'Catheter care created successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating catheter care');
    }
  },
  async updateCatheterCare({ commit, getters }, { id, data }) {
    try {
      const patientId = getters.catheterCareSelectedPatientId;
      const response = await CatheterCareService.updatePatientCatheterCare(patientId, id, data);
      commit('SET_SUCCESS', 'Catheter care updated successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating catheter care');
    }
  },
  async deleteCatheterCare({ commit, getters }, id) {
    try {
      const patientId = getters.catheterCareSelectedPatientId;
      await CatheterCareService.deletePatientCatheterCare(patientId, id);
      commit('SET_SUCCESS', 'Catheter care deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting catheter care');
    }
  },
  removeError({ commit }) {
    commit('SET_ERROR', null);
  },
  removeSuccess({ commit }) {
    commit('SET_SUCCESS', null);
  }
};

const mutations = {
  SET_CATHETER_CARES: (state, catheterCares) => {
    state.catheterCares = catheterCares;
  },
  SET_SELECTED_CATHETER_CARE: (state, catheterCare) => {
    state.selectedCatheterCare = catheterCare;
  },
  clearCatheterCare(state) {
    state.catheterCares = null;
    state.selectedCatheterCare = null;
    state.error = null;
    state.success = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
